<template>
  <b-container>
    <b-row style="margin-top: 40vh;">
      <b-col class="text-center">
        <b-button variant="primary" @click="clear">Clear localStorage</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  methods: {
    clear() {
      // clears the local app storage if errors / bugs accure
      localStorage.clear();
      this.$router.push('/start')
    }
  }
}
</script>

<style>

</style>